import React from 'react'
import { Carousel, Container, Row, Col } from "react-bootstrap"
const ThreeTComponent = ({ data }) => {
    return (
        <Container fluid>
            <Container>
                <Carousel
                    controls={false}
                    className="about-section"
                    interval={5000}
                    defaultActiveIndex={0}

                    style={{ background: '#ffffff' }}>
                    {
                        data.map((carouselItem, index) => (
                            <Carousel.Item key={index + 'about'}>
                                <Container fluid>
                                    <Row className="py-5">
                                        <Col className="carousel-columns" md="7">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div className="line"></div>
                                                <div>
                                                    <h6 className="carousel-section-subheading text-std">{carouselItem.subHeading}</h6>
                                                </div>
                                            </div>

                                            <h2 className="heading">{carouselItem.heading}</h2>
                                            <img
                                                className="d-md-none d-block w-100 about-image"
                                                src={carouselItem.imageSrc}
                                                alt={carouselItem.altText}
                                            />
                                            <p className='text-justify'>{carouselItem.description}</p>

                                        </Col>
                                        <Col className="d-none d-md-block carousel-columns-images about-image">
                                            <img
                                                className="d-block w-100"
                                                src={carouselItem.imageSrc}
                                                alt={carouselItem.altText}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Carousel.Item>
                        ))
                    }
                </Carousel>
                <hr />
            </Container>
        </Container>

    );
};

export default ThreeTComponent;