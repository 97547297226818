import React, { Component } from "react"
import BannerImage from "../components/elements/BannerImage"
import banner from "../images/slicing/service/banner.png"
import WorkStrategy from "../components/services/WorkStrategy"
import CoreValueComponent from "../components/services/CoreValueComponent"
import CaseStudyComponent from "../components/services/CaseStudyComponent"
import TestimonialComponent from "../components/services/TestimonialComponent"
import ThreeTComponent from "../components/aboutus/ThreeTComponent"
import { Row, Col, Container } from "react-bootstrap"
import { strategyDataServicePage } from "../staticdata/homepageServicesData"
import { startupStudio } from "../staticdata/threeTData"

class StartupStudioContainer extends Component {
  render() {
    return (
      <>
        <BannerImage
          activeTab="Startup Studio"
          text={
            "Ideas become obsolete if not executed at the right time, in the right manner, and with the right technology. That is where we come in..."
          }
          bannerImage={banner}
        />
        <Container>
          <Row>
            <Col>
              <h3 className="font-weight-bold pt-5 text-center">
                We add business intelligence to your startup’s DNA ...
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="font-weight-bold text-center text-uppercase text-standard">
                The GTEN startup matrix
              </h3>
            </Col>
          </Row>
        </Container>
        <WorkStrategy data={strategyDataServicePage} />
        <hr />
        <Container className="py-4">
          <Row>
            <Col>
              <p className="text-justify p-3" style={{ lineHeight: 2 }}>
                In a highly dynamic market, influenced heavily by disruption and
                competition, the startup ecology strives on delivering an idea
                fast and first. Business models today, both tech and non-tech
                based, are highly dependent on a strong digital support for
                sustainable stability and growth. This scenario influences the
                notion that startups have the chance to either make it big by
                capitalizing on the opportunity in front of them,or risk being
                over run by competition, based on their execution.
              </p>
            </Col>
            <Col>
              <p className="text-justify p-3" style={{ lineHeight: 2 }}>
                GTEN provides start-ups with the necessary support for
                converting ideas into executable digital solutions.As an
                entrepreneur looking to enter the market with a ready product,
                or an established enterprise planningto launch a new venture,
                the GTEN set-up implements the required infrastructure,
                processes and expertise to add value to your business idea. From
                inception to implementation, we help validate ideas together,
                gain market insights and execute in the shortest time possible.
                The result – a successful brand launch, ready to penetrate and
                capitalize in any given market.
              </p>
            </Col>
          </Row>
        </Container>
        <hr />
        <ThreeTComponent data={startupStudio} />
        <CoreValueComponent />
        <CaseStudyComponent />
        <TestimonialComponent />
      </>
    )
  }
}

export default StartupStudioContainer
